@import '@tradesolution/iceberg-styles/dist/css/iceberg.min.css';

@import '@tradesolution/iceberg-styles/src/scss/_variables.scss';

.info-text {
    color: $GY400;
}

.iceberg-navbar-left {
    background-color: #fff;

    .nav-link {
        color: $GY400;
    }

    .nav-link:hover {
        color: $Link-B500;
    }

    .nav-link.active {
        background-color: transparent;
        color: $Link-B500;
    }
}

.tsTable {

    vertical-align: middle;

    tbody td.fitContent {
        width: 1%;
        white-space: nowrap;
    }
}

.json-popover {
    max-width: 800px;
    padding: 1.5rem;
}

/* Navbar */

.navbar-light .navbar-toggler-icon {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3e%3cpath stroke='rgba(255,255,255, 1)' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}

.navbar-nav .dropdown-toggle:after {
    font-weight: 900;
    height: 24px;
    width: 24px;
    border: none;
    content: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24'%3E%3Cpath fill='white' fill-rule='evenodd' d='M18.887 9.695a.75.75 0 010 1.06l-5.106 5.106a2.268 2.268 0 01-3.207-.002L5.47 10.757a.75.75 0 111.06-1.061l5.104 5.104c.301.301.788.3 1.087.001l5.105-5.105a.75.75 0 011.06 0z' clip-rule='evenodd'/%3E%3C/svg%3E");
    margin-left: 0;
    margin-top: 0.05rem;
    filter: white;
}

.navbar-nav .dropdown-toggle.show:after {
    content: url("data:image/svg+xml, %3Csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24'%3E%3Cpath fill='white' fill-rule='evenodd' d='M10.395 8.61a2.268 2.268 0 013.208 0l5.106 5.105a.75.75 0 01-1.061 1.06L12.542 9.67a.768.768 0 00-1.086.001l-5.104 5.104a.75.75 0 11-1.06-1.06l5.103-5.104z' clip-rule='evenodd'/%3E%3C/svg%3E");
}

.navbar-nav .dropdown-toggle {
    display: flex;
}

// if you want to remove the caret from the dropdown
.noCaret .dropdown-toggle:after {
    display: none;
}

/* End Navbar */