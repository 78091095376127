@import '@tradesolution/iceberg-styles/src/scss/variables';

.errorText {
  color: $Error-R500;
}

@media (max-width: 1400px) {
  .leftButton {
    float: left;
  }
}

@media (max-width: 1149px) {
  .hiddenOnSmallScreen {
    display: none;
  }
}

::placeholder {
  color: black
}

.my-custom-border {
  border: 1px solid #dee2e6;
}

.datePickerWrapper {
  display: flex;
  flex-direction: column;
}

.bigLabel {
  font-size: 1.0rem;
}

.widerSize {
  width: 305px;
}

.formContainer {
  display: flex;
  flex-direction: column;
}

.offcanvasStyle {
  box-sizing: border-box;
}

/* larger screens  */
@media (min-width: 769px) {
  .offcanvasStyle {
    margin-top: 51px !important;
    background-color: #FFFFFF;
    border-left: 1px solid #ccc !important;
  }

  .offcanvasBackdrop {
    opacity: 0 !important;
  }
}

/* mobile screens */
@media (max-width: 768px) {
  .offcanvasStyle {
    overflow: hidden !important;
    border-top-left-radius: 30px !important;
    border-top-right-radius: 30px !important;
    background-color: #FFFFFF;
    border: 1px solid #ccc !important;
  }
}