.rbt {
    display: flex;
    width: fill-available;
}

.rbt-aux {
    margin-left: -30px;
    margin-top: 7px;
    margin-right: 7px;
    z-index: 10;
}

.rbt-close {
    border: none;
}

.rbt-close span {
    font-size: medium;
}